<template>
   <div class="site">
    <main class="site-content is-flex is-flex-direction-column has-background-white">
      <HeaderComponent :title="$t('event.booker')" />
      <BookerComponent/>
    </main>
    </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent";
import BookerComponent from "@/components/BookerComponent.vue";

export default {
    components: {
        HeaderComponent,
        BookerComponent,
    },
    mounted() {
        this.$socket.client.emit("player:JoinSpace", { spaceSlug: "booker" }, () => {});
    },
    beforeMount() {
        if (!this.$store.state.setup.event.booker?.enabled) {
            this.$router.push("/");
        }
    },
}
</script>
<template>
  <div class="overflow-y-auto">


    <div v-if="myReservations.length > 0" class="my-reservations px-4 pt-4">
      <h3 class="title is-5">{{$t('event.myReservations')}}</h3>
      <article
        v-for="reservation in sortedTimeSlots(myReservations)"
        :key="reservation.id"
        class="is-clickable"
        @click="viewReservation(reservation)"
      >
        <div
          class="is-flex is-align-items-center"
          :style="{ color: reservation.spotColor }"
        >
          <d-icon
            v-if="reservation.spotIcon"
            :icon="reservation.spotIcon"
            size="mx-1"
            class="mr-2"
          />
          <span class="has-text-weight-bold"> {{ reservation.spotTitle }}</span>
        </div>
        <div class="is-flex is-align-items-center p-2 pb-4" style="gap: 0.3em">
          <span v-if="reservation.courtName">
            <span class="has-text-weight-bold">{{
              reservation.courtName
            }}</span>
            |</span
          >
          <span class="date has-text-right has-text-weight-bold mr-1">{{
            formatDate(reservation.date)
          }}</span>
          <span style="flex: 1"
            >{{
              formatTimeStartEnd(reservation.startTime, reservation.finishTime)
            }}
          </span>
          <d-icon icon="FaEye" />
        </div>
      </article>
    </div>

    <section class="p-4" v-if="filteredSpots?.length > 0">
      <b-collapse
        v-for="(spot, index) in filteredSpots"
        :key="spot.id"
        :open="openSpot == index"
        @open="openSpot = index"
        :style="spot.color ? { '--spot-color': spot.color } : {}"
        :aria-id="'contentIdForA11y5-' + index"
        class="card mb-4"
        animation="slide"
      >
        <template #trigger="props">
          <div
            class="card-header has-background-white-ter"
            role="button"
            :aria-controls="'contentIdForA11y5-' + index"
            :aria-expanded="props.open"
          >
            <div class="card-header-title">
              <d-icon
                v-if="spot.icon"
                style="color: var(--spot-color)"
                :icon="spot.icon"
                size="mx-1"
                class="mr-2"
              />
              <span class="has-text-weight-bold mr-2">{{ spot.title }}</span>
            </div>
            <a class="card-header-icon">
              <d-icon
                icon="FaChevronDown"
                size="is-small"
                :class="{ open: props.open }"
              />
            </a>
          </div>
        </template>
        <div class="spot card-content px-4 py-3">
          <div
            v-if="spot.description?.trim()"
            class="spot-description is-size-7 mb-4"
            v-html="formatDescription(spot.description)"
          />
          <article v-for="court in filteredCourts(spot.courts)" :key="court.id">
            <header>
              <h3 class="is-title has-text-weight-bold">{{ court.name }}</h3>
            </header>
            <div class="time-slots mb-4">
              <div
                v-for="timeSlot in sortedTimeSlots(court.timeSlots)"
                :key="timeSlot.id"
                @click="seeReservation(spot, court, timeSlot)"
                :class="{
                  'has-background-primary-light': isReserved(timeSlot.id),
                  'is-clickable': !spot.disableReservations,
                }"
                class="p-3"
              >
                <div class="is-flex is-align-items-center" style="gap: 0.3em">
                  <span class="date has-text-right has-text-weight-bold mr-1">
                    {{ formatDate(timeSlot.date) }}</span
                  >
                  <span style="flex: 1">{{
                    formatTimeStartEnd(timeSlot.startTime, timeSlot.finishTime)
                  }}</span>
                  <div
                    v-if="!spot.disableReservations"
                    class="is-flex is-align-items-center mr-3 has-text-weight-bold"
                  >
                    <d-icon icon="FaUserGroup" class="mr-1" />
                    <span> {{ timeSlot.reservations }} </span>
                    <span v-if="spot.hasMaxCapacity"
                      >/{{ spot.maxCapacity }}</span
                    >
                  </div>
                  <template v-if="!spot.disableReservations">
                    <d-icon
                      v-if="
                        timeSlot.reservations >= spot.maxCapacity ||
                        isReserved(timeSlot.id)
                      "
                      icon="FaEye"
                    />
                    <d-icon v-else icon="FaPlus" />
                  </template>
                </div>
              </div>
            </div>
          </article>
        </div>
      </b-collapse>
    </section>

    <section v-else class="p-4">
      <div v-for="(_,i) in 3" :key="i" :class="{'mt-4': i > 0}">
        <b-skeleton size="is-large" height="48"/>
      </div>
    </section>
    <!-- Confirm Reservation Modal -->
    <b-modal v-model="showConfirmModal" title="Book spot">
      <div v-if="showConfirmModal" class="modal-card">
        <header
          class="modal-card-head is-flex is-align-items-center has-text-white"
        >
          <d-icon
            v-if="selectedReservation.spot.icon"
            :icon="selectedReservation.spot.icon"
            size="mx-1"
            class="mr-2"
          />
          <h3 class="modal-card-title has-text-white is-size-5">
            {{ selectedReservation.spot.title }}
          </h3>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <p class="is-size-5 has-text-weight-bold mb-2">
            {{ selectedReservation.court.name }}
          </p>
          <p class="is-size-6 mb-2">
            <span class="has-text-weight-bold mr-1">{{
              formatDate(selectedReservation.timeSlot.date)
            }}</span>
            {{
              formatTimeStartEnd(
                selectedReservation.timeSlot.startTime,
                selectedReservation.timeSlot.finishTime
              )
            }}
          </p>
          <ol class="ml-5">
            <li v-for="user in selectedReservation.users" :key="user.id">
              <div
                :class="{
                  'has-text-weight-bold': myBook(user.id) || user.isMine,
                }"
              >
                {{ user.name }}
              </div>
            </li>
          </ol>
          <div class="mt-3" v-if="canReservate">
            <h4 class="is-size-5 has-text-weight-bold mb-2">{{$t('event.reservations')}}:</h4>
            <ul style="list-style-type: none">
              <li
                v-if="!isMyUserInList"
                class="is-flex is-align-items-center mb-1"
              >
                <b-input
                  class="is-flex-grow-1 bg-transparent"
                  type="text"
                  readonly
                  :value="$store.state.user.profile.username"
                />
                <div class="empty"></div>
              </li>

              <li
                v-for="(guest, index) in selectedReservation.confirmedGuests"
                :key="index"
                class="mb-1"
              >
                <div class="is-flex is-align-items-center">
                  <b-input
                    class="is-flex-grow-1 bg-transparent"
                    type="text"
                    readonly
                    :value="guest"
                  />
                  <b-button
                    type="is-ghost"
                    size="is-small"
                    class="action-guest"
                    @click="removeGuest(index)"
                  >
                    <d-icon icon="FaXmark" />
                  </b-button>
                </div>
              </li>
            </ul>
            <div v-if="showModalInput" class="is-flex is-align-items-center">
              <b-input
                class="is-flex-grow-1"
                v-model="selectedReservation.editingGuest"
                type="text"
                :placeholder="$t('event.addGuest')"
              />
              <b-button
                type="is-ghost"
                size="is-small"
                class="action-guest"
                @click="addGuest"
                :disabled="!isEditingUserValid"
              >
                <d-icon icon="FaPlus" />
              </b-button>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot has-background-white-ter is-justify-content-end">
          <b-button
            type="is-primary"
            size="is-small"
            outlined
            expanded
            :disabled="loadingConfirm"
            @click="showConfirmModal = false"
            >{{ canReservate ? $t('system.cancel') : $t('system.close') }}</b-button
          >
          <b-button
            v-if="canReservate"
            type="is-primary"
            size="is-small"
            expanded
            :disabled="isConfirmDisabled"
            :loading="loadingConfirm"
            @click="ConfirmReservation"
            >{{ $t('event.reservate') }}</b-button
          >
        </footer>
      </div>
    </b-modal>

    <!-- View Reservation Modal -->
    <b-modal v-model="showViewModal" :title="$t('event.viewReservation')">
      <div v-if="showViewModal" class="modal-card">
        <header
          class="modal-card-head is-flex is-align-items-center has-text-white"
        >
          <d-icon
            v-if="myReservation.spot.icon"
            :icon="myReservation.spot.icon"
            size="mx-1"
            class="mr-2"
          />
          <h3 class="modal-card-title has-text-white is-size-5">
            {{ myReservation.spot.title }}
          </h3>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <p
            v-if="myReservation.courtName"
            class="is-size-5 has-text-weight-bold mb-0"
          >
            {{ myReservation.courtName }}
          </p>
          <p
            v-if="myReservation.spot.description"
            class="is-size-6 mb-2"
          >
            {{ myReservation.spot.description }}
          </p>
          <p class="is-size-6">
            <span class="has-text-weight-bold mr-1">{{
              formatDate(myReservation.date)
            }}</span>
            {{
              formatTimeStartEnd(
                myReservation.startTime,
                myReservation.finishTime
              )
            }}
          </p>
          <section v-if="myReservation.allUsers" class="mt-4">
            <p class="is-size-5 has-text-weight-bold mb-2">{{$t('event.participants')}}</p>
            <ol class="mb-1 ml-5">
              <template v-for="user in myReservation.allUsers">
                <li
                  :key="user.id"
                  :class="{
                    'has-text-weight-bold': myBook(user.id) || user.isMine,
                  }"
                >
                  {{ user.name }}
                </li>
              </template>
            </ol>
          </section>
        </section>
        <footer class="modal-card-foot has-background-white-ter is-justify-content-end">
          <b-button
            type="is-primary"
            size="is-small"
            outlined
            expanded
            @click="removeReservation(myReservation.id)"
            ><d-icon icon="FaTrash" />{{$t('event.delete')}}</b-button
          >
          <b-button
            type="is-primary"
            size="is-small"
            expanded
            @click="showViewModal = false"
            >{{$t('system.close')}}</b-button
          >
        </footer>
      </div>
    </b-modal>

    <!-- Remove Reservation Modal -->
    <b-modal v-model="showRemoveModal" :title="$t('event.deleteReservation')">
      <div v-if="showRemoveModal" class="modal-card">
        <header class="modal-card-head">
          <h3 class="modal-card-title has-text-white is-size-5">{{$t('event.deleteReservation')}}</h3>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <p class="">{{$t('event.deleteReservationConfirm')}}</p>
        </section>
        <footer class="modal-card-foot has-background-white-ter is-justify-content-end">
          <b-button
            type="is-primary"
            size="is-small"
            outlined
            expanded
            :loading="loadingRemove"
            @click="ConfirmRemove"
            ><d-icon icon="FaTrash" />{{$t('event.yesDelete')}}</b-button
          >
          <b-button
            type="is-primary"
            size="is-small"
            expanded
            :disabled="loadingRemove"
            @click="showRemoveModal = false"
            >{{$t('event.noCancel')}}</b-button
          >
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openSpot: null,
      showConfirmModal: false,
      loadingConfirm: false,
      showViewModal: false,
      showRemoveModal: false,
      loadingRemove: false,
      selectedReservation: {},
      myReservation: {},
      removing: {},
    };
  },
  computed: {
    myReservations() {
      return this.$store.state.event.myReservations;
    },
    spots() {
      return this.$store.state.event.booker;
    },
    showModalInput() {
      return (
      this.selectedReservation.timeSlot.reservations +
        this.selectedReservation.confirmedGuests.length +
        (this.isMyUserInList ? 0 : 1) <
      this.selectedReservation.spot.maxCapacity
      );
    },
    isEditingUserValid() {
      return this.selectedReservation.editingGuest.trim().length > 0;
    },
    isMyUserInList() {
      return this.selectedReservation.users.some(
      (user) => user.id === this.$store.state.user.profile.id
      );
    },
    isConfirmDisabled() {
      return (
        this.isMyUserInList &&
        this.selectedReservation.confirmedGuests.length === 0 &&
        !this.isEditingUserValid
      );
    },
    sortedTimeSlots() {
      return (timeSlots) => {
        return timeSlots.slice().sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          if (dateA - dateB !== 0) {
            return dateA - dateB;
          }
          const startTimeA = new Date(a.startTime);
          const startTimeB = new Date(b.startTime);
          return startTimeA - startTimeB;
        });
      };
    },
    canReservate(){
      return this.selectedReservation.timeSlot.reservations < this.selectedReservation.spot.maxCapacity
    },
    isReserved() {
      return (slotId) => {
        return this.myReservations.some(
          (reservation) => reservation.timeSlotId === slotId
        );
      };
    },
    sortedSpots() {
      if (!this.spots) return [];
      return this.spots.slice().sort((a, b) => a.order - b.order);
    },
    filteredCourts() {
      return (courts) => {
        return courts.filter(court => !court.hidden);
      };
    },
    filteredSpots() {
      return this.sortedSpots.filter(spot => {
        return this.filteredCourts(spot.courts).length > 0;
      });
    },
  },
  methods: {
    formatDescription(description) {
      return description.replace(/\n/g, "<br>");
    },

    myBook(id) {
      if (!id) return false;
      return id === this.$store.state.user.profile.id;
    },
    async seeReservation(spot, court, timeSlot) {

      if(spot.disableReservations) return;

      const users = await this.$store.dispatch(
        "event/GetReservations",
        timeSlot.id
      );

      console.log("users", users);

      timeSlot.reservations = users.length;
      console.log('timeSlot.reservations', timeSlot.reservations);

      this.selectedReservation = {
        spot: spot,
        court: court,
        timeSlot: timeSlot,
        users: users,
        availableSpots: spot.maxCapacity - users.length,
        confirmedGuests: [],
        editingGuest: "",
      };

      this.showConfirmModal = true;
    },
    addGuest() {
      const newGuest = this.selectedReservation.editingGuest;
      this.selectedReservation.editingGuest = "";
      this.selectedReservation.confirmedGuests.push(newGuest);
    },
    removeGuest(index) {
      this.selectedReservation.confirmedGuests.splice(index, 1);
    },
    async ConfirmReservation() {
      this.loadingConfirm = true;
      const guests = this.selectedReservation.confirmedGuests;
      if (this.isEditingUserValid) {
        guests.push(this.selectedReservation.editingGuest);
      }

      const data = {
        spotId: this.selectedReservation.spot.id,
        courtId: this.selectedReservation.court.id,
        timeSlotId: this.selectedReservation.timeSlot.id,
        guests: guests,
      };

      const res = await this.$store.dispatch("event/ReservateSlot", data);

      if (res.success) {
        this.$buefy.notification.open({
          type: "is-success",
          message: this.$t('event.reservationSuccess'),

        });
        this.$TrackUserEvent("Booked Slot", data);
      } else {
        this.$buefy.notification.open({
          type: "is-danger",
          message: this.$t('event.reservationError'),
        });
        console.log(res.error);
      }

      this.loadingConfirm = false;
      this.showConfirmModal = false;
    },
    async viewReservation(reservation) {

      console.log('viewReservation', reservation);
      const spot = this.spots.find(spot => spot.id === reservation.spotId);

      this.myReservation = reservation;

      this.myReservation.spot = spot;

      const users = await this.$store.dispatch(
        "event/GetReservations",
        reservation.timeSlotId
      );

      this.myReservation.allUsers = users;
      this.showViewModal = true;
    },
    async removeReservation(id) {
      this.removing = {
        id,
      };
      this.showRemoveModal = true;
    },
    async ConfirmRemove() {
      this.loadingRemove = true;
      try {
        await this.$store.dispatch("event/RemoveReservation", this.removing);
        this.$buefy.notification.open({
          type: "is-success",
          message: this.$t('event.reservationDeleted'),
        });
        this.CargarBookerData(); // recargo toda la data
        this.$TrackUserEvent("Removed Slot", this.removing);
      } catch (e) {
        console.error("Error eliminando reserva", e);
        // this.$buefy.notification.open({
        //   type: "is-danger",
        //   message: this.$t('event.reservationDeleteError'),
        // });
      } finally {
        this.loadingRemove = false;
        this.showRemoveModal = false;
        this.showViewModal = false;
      }
    },
    formatTime(time) {
      return new Date(time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        day: "2-digit",
        month: "2-digit",
      });
    },
    formatTimeStartEnd(start, finish) {
      return `${this.formatTime(start)} - ${this.formatTime(finish)}`;
    },
    async CargarBookerData() {
      // Wait for user data to be availabe
      while (!this.$store.state.user.profile.id) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      
      // Cargo los dos en paralelo
      const requests = [
        this.$store.dispatch("event/GetRawData", "booker"),
        this.$store.dispatch("event/GetMyReservations"),
      ];

      await Promise.all(requests);
    },
  },
  async mounted() {
    this.CargarBookerData();

    this.formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
      day: "numeric",
      month: "long",
    });
    this.$store.commit("StopLoading");
  },
};
</script>

<style lang="scss" scoped>
.overflow-y-auto {
  overflow-y: auto;
}
.card {
  box-shadow: none;

  .card-header {
    box-shadow: none;
  }

  .icon {
    transform: rotate(0);
    transition: transform 200ms;

    &.open {
      transform: rotate(-180deg);
    }
  }
}

.modal-card-body {
  max-height: calc(100vh - 300px);
}
.my-reservations {
  border-bottom: dashed 1px #dbdbdb;
}
.spot {
  header {
    border-bottom: solid 1px #dbdbdb;
    h3 {
      color: var(--spot-color);
    }
  }

  .time-slots {
    
    & > div {
      border-bottom: dashed 1px #dbdbdb;
    }
  }
}
:depth(svg) {
  color: var(--spot-color);
}

.bg-transparent :deep(input) {
  background-color: transparent;
}

.empty,
:deep(.button.action-guest) {
  width: 30px;
  height: 30px;
}
:deep(.button.action-guest[disabled]) {
  background: none;
  border-width: 0;
}
</style>
